<template>
    <div class="row dashboard mt-3">
        <div class="col-12">
            <div class="row gy-3">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
            </div>
            <div class="row order-breakdown gy-3">
                <div class="col-6 col-lg-3">
                    <div class="card">
                        <div class="card-body position-relative">
                            <h5 class="soep-title">Draft orders</h5>
                            <h2 class="">{{result.draft}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="card">
                        <div class="card-body position-relative">
                            <h5 class="soep-title">Active orders</h5>
                            <h2 class="">{{result.active}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="card">
                        <div class="card-body position-relative">
                            <h5 class="soep-title"> In Progress </h5>
                            <h2 class="">{{result.progress}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="card">
                        <div class="card-body position-relative">
                            <h5 class="soep-title"> Completed </h5>
                            <h2 class="">{{result.completed}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-4">
                <div class="col-12">
                    <order-list />
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12">
                    <h5 class="mb-3">Need more power for your business</h5>
                    <router-link to="/subscription/plan" class="btn btn-dark"> Upgrade plan </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import OrderList from "./orders/OrderList.vue"
import UserEmailVerification from "@/components/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        OrderList,
        UserEmailVerification,
    },
    data(){
        return {
            result:{
                total:0,
                draft: 0,
                active: 0,
                progress: 0,
                completed :0
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
    },
    created(){
        this.$http.get('/analytics')
        .then(response => {
          if(response.data.success){
            this.result = response.data.data
          }
      })
    }
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
    .order-breakdown .soep-title{
        font-size: 13px;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}


</style>
